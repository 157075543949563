import React, { useState } from "react";

const formFields = [
  { id: "name", label: "Name", type: "text", required: true },
  { id: "college", label: "College", type: "text", required: true },
  { id: "batch", label: "Batch", type: "text", required: true },
  { id: "fbIdName", label: "Facebook ID Name", type: "text", required: true },
  {
    id: "fbIdLink",
    label: "Facebook ID Link ",
    type: "text",
    required: true,
  },
  {
    id: "transactionId",
    label: "Transaction ID ***",
    type: "text",
    required: true,
  },
  {
    id: "courseName",
    label: "Select Course",
    type: "select",
    required: true,
  },
  {
    id: "coupon",
    label: "Coupon",
    type: "text",
    required: false,
  },
];

const courses = ["Biology", "Medical"];

const Payment = () => {
  const [formData, setFormData] = useState({
    name: "",
    college: "",
    batch: "",
    fbIdName: "",
    fbIdLink: "",
    transactionId: "",
    courseName: "",
    coupon: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/paymentinfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Payment information saved successfully!");
      } else {
        const errorData = await response.json();
        alert("Error: " + errorData.error);
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  return (
    <div className="text-slate-800 flex flex-col items-center justify-center w-full h-full">
      <h2 className="py-5 text-red-500 font-bold text-2xl">
        Payment & Registration
      </h2>
      <form
        onSubmit={handleSubmit}
        className="border px-4 py-5 my-5 rounded-md w-[400px] h-auto"
      >
        {formFields.map(({ id, label, type, required }) => (
          <div key={id} className="mb-4">
            <label htmlFor={id}>{label}</label>
            {type === "select" ? (
              <select
                id={id}
                name={id}
                value={formData[id]}
                onChange={handleChange}
                required={required}
                className="block w-full mt-1 bg-gray-100 rounded-sm px-2 py-1"
              >
                <option value="" disabled>
                  Select a course
                </option>
                {courses.map((course) => (
                  <option key={course} value={course}>
                    {course}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={type}
                id={id}
                name={id}
                value={formData[id]}
                onChange={handleChange}
                required={required}
                className="block w-full mt-1 bg-gray-100 rounded-sm px-2 py-1"
              />
            )}
          </div>
        ))}
        <button
          type="submit"
          className="mt-4 border w-full rounded-md bg-slate-900 text-gray-100 hover:bg-green-500 hover:text-slate-900 font-bold py-1"
        >
          Submit
        </button>
        <p className="text-slate-500 pt-4 text-sm">
          N.B: Once you submit with correct transaction id, your request to
          enroll into the course will be manually approved soon. Thank You
        </p>
      </form>
    </div>
  );
};

export default Payment;
